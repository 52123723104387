var browsers = {
    firefox: /firefox\/(\d+(\.\d*)*)/i,
    ie: /(?:\b(MS)?IE\s+|\bTrident\/7\.0;.*\s+rv:)(\d+)/i,
    edge: /Edge?\/(\d+(\.\d*)*)/i,
    chrome: /Chrome\/(\d+(\.\d*)*) safari\/(\d+(\.\d*))$/i,
    safari: /safari\/(\d+(\.\d*)*)$/i
};

var userAgent, browser, browserVersion;

var SESSION_STATE_KEY = 'sessionState';

var notificationContainer = document.getElementById('notificationContainer');
sl_tr_start();
var notificationBarText = 'You are using an unsupported browser. Please switch to ';
var notificationBarThanksText = 'Thanks! ';
var notificationBarCloseText = 'Close ';
sl_tr_end();
var notificationBarLinks = '<a href="https://www.mozilla.org/en-US/firefox/new/">FireFox</a>,\n' +
    '<a href="https://support.apple.com/downloads/safari">Safari</a>,\n' +
    '<a href="https://www.google.com/chrome/">Chrome</a>, ' +
    '<a href="https://www.microsoft.com/en-us/windows/microsoft-edge">Microsoft Edge</a> ';
var notificationBarCloseLink = '&emsp;&emsp;<span class="closeContainer"><a href="javascript:void(0)" class="closeLink" id="closeButton">' +
    '{close} <img class="closeIcon" src="/cdn/vendor/ihgimages/v1.0.0/font-awesome/times.svg"></a></span>';

function closeClick() {
    hideContent();
    saveFlagToSessionState();
}

var sessionStateJSON = sessionStorage.getItem(SESSION_STATE_KEY);
if (sessionStateJSON) {
    if (JSON.parse(sessionStateJSON).disableNotification !== true) {
        outDatedBrowser();
    }
} else {
    outDatedBrowser();
}

function addNotificationWindow() {
    var unsupportedBrowserText = document.getElementById('unsupportedBrowserText');
    notificationBarCloseLink = notificationBarCloseLink.replace('{close}', notificationBarCloseText);
    !!unsupportedBrowserText ? (unsupportedBrowserText.innerHTML = notificationBarText + notificationBarLinks + notificationBarThanksText + notificationBarCloseLink): null;
    var closeButton = document.getElementById('closeButton');
    !!closeButton ? closeButton.addEventListener('click', closeClick) : null;
    !!notificationContainer ? notificationContainer.style.display = 'block' : null;
}

function outDatedBrowser() {
    userAgent = navigator.userAgent;
    getBrowser();
    if (!isBrowserSupported()) {
        addNotificationWindow();
    }
}

function getBrowser() {
    var result;
    for (var browserPattern in browsers) {
        result = userAgent.match(browsers[browserPattern]);
        if (result && result.length) {
            browser = browserPattern;
            browserVersion = result[1] || result[2] || result[3];
            break;
        }
    }
}

function isBrowserSupported() {
    if (browser) {
        var browserV = browserVersion.split('.')[0];
        switch (browser) {
            case 'chrome':
                return browserV >= 94;
            case 'safari':
                return browserV >= 13;
            case 'firefox':
                return browserV >= 94;
            case 'edge':
                return browserV >= 94;
            default:
                return false;
        }
    }
    return false;
}

function hideContent() {
    notificationContainer.style.height = 0;
}

function saveFlagToSessionState() {
    var sessionState;
    var sessionStateJSON = sessionStorage.getItem(SESSION_STATE_KEY);
    if (sessionStateJSON) {
        sessionState = JSON.parse(sessionStateJSON);
        sessionState.disableNotification = true;
    } else {
        sessionState = {
            disableNotification: true
        };
    }
    sessionStorage.setItem(SESSION_STATE_KEY, JSON.stringify(sessionState));
}
